
/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/


dl,
dt,
dd,
ol,
ul,
li {
  margin: 0;
  padding: 0;
}

.clear {
  display: block;
  clear: both;
  overflow: hidden;
  width: 100%;
  height: 0;
  font-size: 0;
  line-height: 0;
}

::selection {
  background: $theme-color;
  color: #ffffff;
  text-shadow: none;
}

::-moz-selection {
  background: $theme-color; /* Firefox */
  color: #ffffff;
  text-shadow: none;
}

::-webkit-selection {
  background: $theme-color; /* Safari */
  color: #ffffff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}


/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/


body {
  color: $body-color;
  font-size: $font-size-base;
  font-family: $body-font;
  line-height: $line-height-base;
}

a {
  color: $theme-color;
  text-decoration: none !important;
  &:hover {
    color: #222222;
  }
  img {
    border: none;
  }
}

img {
  max-width: 100%;
}
iframe {
  border: none !important;
}


/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 30px 0;
  color: $heading-color;
  font-weight: 600;
  font-family: $heading-font;
  line-height: $line-height-base;
  &.bordered {
    border-bottom: 2px solid $theme-color-light;
  }
}

h5,
h6 {
  margin-bottom: 20px;
}

h1 {
  font-size: $font-size-h1;
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
}
h6 {
  font-size: $font-size-h6;
}

h4 {
  font-weight: 600;
}

h5,
h6 {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  > span:not(.nocolor) {
    color: $theme-color;
  }
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 30px;
}

small {
  font-family: $body-font;
}

#wrapper {
  position: relative;
  float: none;
  margin: 0 auto;
  width: 1220px;
  background-color: #ffffff;

  @include box-shadow(0 0 10px rgba(0,0,0,0.1));
}
