@charset 'utf-8';

/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 4.1.1

    LESS Stylesheet

-----------------------------------------------------------------------------------*/

// Initialize
@import 'components/variables.scss';
@import 'components/mixins.scss';
@import 'components/elements/font-icons.scss';
@import 'vendor/bootstrap/bootstrap-sprockets.scss';
@import 'vendor/bootstrap/bootstrap.scss';
// Core CSS
@import 'components/typography.scss';
@import 'components/helpers.scss';
@import 'components/layouts.scss';
// Content Blocks
@import 'components/topbar.scss';
@import 'components/header.scss';
@import 'components/sliders.scss';
@import 'components/swiper.scss';
@import 'components/pagetitle.scss';
@import 'components/content.scss';
@import 'components/portfolio.scss';
@import 'components/blog.scss';
@import 'components/shop.scss';
@import 'components/dark.scss';
@import 'components/events.scss';
@import 'components/twitter.scss';
@import 'components/animate.scss';
@import 'components/sidenav.scss';
@import 'components/sticky-social.scss';
// Shortcodes
@import 'components/shortcodes.scss';
// Footer
@import 'components/footer.scss';
// Widgets
@import 'components/widgets.scss';
@import 'components/elements/carousel.scss';
@import 'components/elements/typeahead.scss';
// Extras
@import 'components/extras.scss';
@import 'components/responsive-rtl.scss';
@import 'components/responsive.scss';
@import 'components/cifb.scss';
@import 'components/_accessibility.scss';
