
/* ----------------------------------------------------------------

	extras.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/

.table {
	.success {
		color: $success;
	}
	.info {
		color: $info;
	}
	.warning {
		color: $warning;
	}
	.danger {
		color: $danger;
	}
}

.btn-link {
	color: $theme-color;
	&:hover,
	&:focus {
		color: #222;
	}
}

.boot-tabs {
	margin-bottom: 20px !important;
}

.carousel-control {
	.icon-chevron-left,
	.icon-chevron-right {
		position: absolute;
		top: 47.5%;
		z-index: 5;
		display: inline-block;
	}
	.icon-chevron-left {
		left: 50%;
	}
	.icon-chevron-right {
		right: 50%;
	}
}

.responsive-utilities {
	th {
		small {
			display: block;
			font-weight: 400;
			color: #999;
		}
	}
}

.show-grid {
	[class^=col-] {
		padding-top: 10px;
		padding-bottom: 10px;
		background-color: #EEE;
		border: 1px solid #DDD;
	}
}

.img-circle {
	@include border-radius(50% !important);
}

.pagination {
	> .active {
		> a,
		> span,
		> a:hover,
		> span:hover,
		> a:focus,
		> span:focus {
			color: #FFF !important;
			background-color: $theme-color !important;
			border-color: $theme-color !important;
		}
	}
}

label {
	&.radio,
	&.checkbox {
		padding-left: 20px;
	}
}

.input-group-lg > .input-group-addon { line-height: 1; }


/* ----------------------------------------------------------------
	Cookie Notification
-----------------------------------------------------------------*/

#cookie-notification {
	position: fixed;
	top: auto;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 60px;
	line-height: 60px;
	background-color: #EEE;
	color: #444;
	z-index: 99;
	@include box-shadow(0px -1px 4px 0px rgba(0,0,0,0.15));
}

.cookie-noti-btn { margin-top: 15px; }

/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/

.stretched {
	#wrapper {
		width: 100%;
		margin: 0;
		box-shadow: none;
	}
}


/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/

body:not(.no-transition) #wrapper,
.animsition-overlay {
	position: relative;
	opacity: 0;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	text-align: center;
	background-color: #FFF;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner > div {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -9px;
	margin-left: 13px;
	width: 18px;
	height: 18px;
	background-color: #DDD;
	@include border-radius(100%);
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner .css3-spinner-bounce1 {
	margin-left: -31px;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.css3-spinner .css3-spinner-bounce2 {
	margin-left: -9px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0.0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
  } 40% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
  }
}


.css3-spinner > .css3-spinner-flipper {
	width: 32px;
	height: 32px;
	margin-top: -16px;
	margin-left: -16px;
	@include border-radius(0);
	-webkit-animation: rotateplane 1.2s infinite ease-in-out;
	animation: rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
	0% { -webkit-transform: perspective(120px) }
	50% { -webkit-transform: perspective(120px) rotateY(180deg) }
	100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	} 50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	} 100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.css3-spinner > .css3-spinner-double-bounce1,
.css3-spinner > .css3-spinner-double-bounce2 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	@include border-radius(50%);
	opacity: 0.6;
	-webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
	animation: cssspinnerbounce 2.0s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes cssspinnerbounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes cssspinnerbounce {
  0%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
  } 50% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
  }
}

.css3-spinner > .css3-spinner-rect1,
.css3-spinner > .css3-spinner-rect2,
.css3-spinner > .css3-spinner-rect3,
.css3-spinner > .css3-spinner-rect4,
.css3-spinner > .css3-spinner-rect5 {
	height: 30px;
	width: 6px;
	margin-top: -15px;
	margin-left: -21px;
	@include border-radius(0);
	-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	animation: stretchdelay 1.2s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-rect2 {
	margin-left: -12px;
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.css3-spinner > .css3-spinner-rect3 {
	margin-left: -3px;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.css3-spinner > .css3-spinner-rect4 {
	margin-left: 6px;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.css3-spinner > .css3-spinner-rect5 {
	margin-left: 15px;
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
	transform: scaleY(0.4);
	-webkit-transform: scaleY(0.4);
  }  20% {
	transform: scaleY(1.0);
	-webkit-transform: scaleY(1.0);
  }
}


.css3-spinner > .css3-spinner-cube1,
.css3-spinner > .css3-spinner-cube2 {
	width: 16px;
	height: 16px;
	@include border-radius(0);
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: cubemove 1.8s infinite ease-in-out;
	animation: cubemove 1.8s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-cube2 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes cubemove {
  25% {
	transform: translateX(42px) rotate(-90deg) scale(0.5);
	-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  } 50% {
	transform: translateX(42px) translateY(42px) rotate(-179deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% {
	transform: translateX(42px) translateY(42px) rotate(-180deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% {
	transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  } 100% {
	transform: rotate(-360deg);
	-webkit-transform: rotate(-360deg);
  }
}


.css3-spinner > .css3-spinner-scaler {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: scaleout 1.0s infinite ease-in-out;
	animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
  0% { -webkit-transform: scale(0.0) }
  100% {
	-webkit-transform: scale(1.0);
	opacity: 0;
  }
}

@keyframes scaleout {
  0% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
  } 100% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
	opacity: 0;
  }
}

.css3-spinner > .css3-spinner-grid-pulse {
	width: 48px;
	height: 48px;
	margin-top: -24px;
	margin-left: -24px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-grid-pulse > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	margin: 2px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	float: left;
	-webkit-animation-name: ball-grid-pulse;
	animation-name: ball-grid-pulse;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

.css3-spinner-grid-pulse > div:nth-child(1) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(2) {
	-webkit-animation-delay: 0.32s;
	animation-delay: 0.32s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(3) {
	-webkit-animation-delay: 0.71s;
	animation-delay: 0.71s;
	-webkit-animation-duration: 0.88s;
	animation-duration: 0.88s;
}

.css3-spinner-grid-pulse > div:nth-child(4) {
	-webkit-animation-delay: 0.62s;
	animation-delay: 0.62s;
	-webkit-animation-duration: 1.06s;
	animation-duration: 1.06s;
}

.css3-spinner-grid-pulse > div:nth-child(5) {
	-webkit-animation-delay: 0.31s;
	animation-delay: 0.31s;
	-webkit-animation-duration: 0.62s;
	animation-duration: 0.62s;
}

.css3-spinner-grid-pulse > div:nth-child(6) {
	-webkit-animation-delay: -0.14s;
	animation-delay: -0.14s;
	-webkit-animation-duration: 1.48s;
	animation-duration: 1.48s;
}

.css3-spinner-grid-pulse > div:nth-child(7) {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
	-webkit-animation-duration: 1.47s;
	animation-duration: 1.47s;
}

.css3-spinner-grid-pulse > div:nth-child(8) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	-webkit-animation-duration: 1.49s;
	animation-duration: 1.49s;
}

.css3-spinner-grid-pulse > div:nth-child(9) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
}

@-webkit-keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}

@keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}


.css3-spinner > .css3-spinner-clip-rotate {
	width: 33px;
	height: 33px;
	margin-top: -17px;
	margin-left: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-clip-rotate > div {
	border-radius: 100%;
	border: 2px solid #DDD;
	border-bottom-color: transparent !important;
	height: 33px;
	width: 33px;
	background: transparent !important;
	-webkit-animation: rotate 1s 0s linear infinite;
	animation: rotate 1s 0s linear infinite;
}

@keyframes rotate {
	0% {
	-webkit-transform: rotate(0deg) scale(1);
	transform: rotate(0deg) scale(1); }

	50% {
	-webkit-transform: rotate(180deg) scale(0.6);
	transform: rotate(180deg) scale(0.6); }

	100% {
	-webkit-transform: rotate(360deg) scale(1);
	transform: rotate(360deg) scale(1); }
}


.css3-spinner > .css3-spinner-ball-rotate {
	width: 12px;
	height: 12px;
	margin-top: -6px;
	margin-left: -6px;
	background-color: transparent !important;
	-webkit-animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
	animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
}

.css3-spinner-ball-rotate > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: relative;
}

.css3-spinner-ball-rotate > div:nth-child(1), .css3-spinner-ball-rotate > div:nth-child(3) {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	content: "";
	position: absolute;
	opacity: 0.7;
}

.css3-spinner-ball-rotate > div:nth-child(1) {
	top: 0px;
	left: -22px;
}

.css3-spinner-ball-rotate > div:nth-child(3) {
	top: 0px;
	left: 22px;
}


@-webkit-keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}

@keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}


.css3-spinner > .css3-spinner-zig-zag {
	background-color: transparent !important;
	-webkit-transform: translate(-15px, -15px);
	-ms-transform: translate(-15px, -15px);
	transform: translate(-15px, -15px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-zig-zag > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: absolute;
	margin-left: 15px;
	top: 4px;
	left: -7px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-zig-zag > div:first-child {
	-webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
	animation: ball-zig-deflect 1.5s 0s infinite linear;
}

.css3-spinner-zig-zag > div:last-child {
	-webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
	animation: ball-zag-deflect 1.5s 0s infinite linear;
}


@-webkit-keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@-webkit-keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}


.css3-spinner > .css3-spinner-ball-scale-multiple {
	width: 60px;
	height: 60px;
	margin-left: -30px;
	margin-top: -30px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-scale-multiple > div {
	background-color: #DDD;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 60px;
	height: 60px;
	-webkit-animation: ball-scale-multiple 1.25s 0s linear infinite;
	animation: ball-scale-multiple 1.25s 0s linear infinite;
}

.css3-spinner-ball-scale-multiple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-ball-scale-multiple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}

@-webkit-keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}

@keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}


.css3-spinner > .css3-spinner-triangle-path {
	background-color: transparent !important;
	-webkit-transform: translate(-29.994px, -37.50938px);
	-ms-transform: translate(-29.994px, -37.50938px);
	transform: translate(-29.994px, -37.50938px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-triangle-path > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #DDD;
}

.css3-spinner-triangle-path > div:nth-child(1) {
	-webkit-animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(2) {
	-webkit-animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(3) {
	-webkit-animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-of-type(1) { top: 50px; }

.css3-spinner-triangle-path > div:nth-of-type(2) { left: 25px; }

.css3-spinner-triangle-path > div:nth-of-type(3) {
	top: 50px;
	left: 50px;
}

@-webkit-keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}


.css3-spinner > .css3-spinner-ball-pulse-sync {
	width: 70px;
	height: 34px;
	margin-left: -35px;
	margin-top: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-pulse-sync > div {
	display: inline-block;
	background-color: #DDD;
	width: 14px;
	height: 14px;
	margin: 10px 3px 0;
	border-radius: 100%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-ball-pulse-sync > div:nth-child(0) {
	-webkit-animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(1) {
	-webkit-animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(2) {
	-webkit-animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(3) {
	-webkit-animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
}


@-webkit-keyframes ball-pulse-sync {
  33% {
	@include translateY(10px);
	opacity: 0.85;
	}

  66% {
	@include translateY(-10px);
	opacity: 0.7;
	}

  100% {
	@include translateY(0);
	opacity: 1;
	}
}

@keyframes ball-pulse-sync {
  33% {
	@include translateY(10px);
	opacity: 0.85;
	}

  66% {
	@include translateY(-10px);
	opacity: 0.7;
	}

  100% {
	@include translateY(0);
	opacity: 1;
	}
}


.css3-spinner > .css3-spinner-scale-ripple {
	width: 50px;
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-scale-ripple > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	top: 0;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: 2px solid #DDD;
	-webkit-animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
	animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
}

.css3-spinner-scale-ripple > div:nth-child(0) {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.css3-spinner-scale-ripple > div:nth-child(1) {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}

.css3-spinner-scale-ripple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-scale-ripple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}


@-webkit-keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}

@keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}
