
/* ----------------------------------------------------------------

	Layouts.scss

-----------------------------------------------------------------*/


/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/

.section {
	position: relative;
	margin: $section-margin 0;
	padding: $section-padding 0;
	background-color: $section-bg;
	overflow: hidden;

	.container {
		z-index: 2;
	}

	.container + .video-wrap {
		z-index: 1;
	}
}

.parallax {
	background-color: transparent;
	background-attachment: fixed;
	background-position: 50% 0;
	background-repeat: no-repeat;
	overflow: hidden;
}

.mobile-parallax,
.video-placeholder {
	background-size: cover !important;
	background-attachment: scroll !important;
	background-position: center center;
}

.revealer-image {
	position: relative;
	bottom: -($section-padding * 2);
	@include transition(bottom .3s ease-in-out);
}

.section:hover .revealer-image { bottom: -$section-padding; }


/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/


.postcontent {
	width: $postcontent;

	&.bothsidebar {
		width: $bothsidebar;
	}
}

.sidebar { width: $sidebar; }
.col_full { width: 100%; }
.col_half { width: 48%; }
.col_one_third { width: 30.63%; }
.col_two_third { width: 65.33%; }
.col_one_fourth { width: 22%; }
.col_three_fourth { width: 74%; }
.col_one_fifth { width: 16.8%; }
.col_two_fifth { width: 37.6%; }
.col_three_fifth { width: 58.4%; }
.col_four_fifth { width: 79.2%; }
.col_one_sixth { width: 13.33%; }
.col_five_sixth { width: 82.67%; }

body.stretched .container-fullwidth {
	position: relative;
	padding: 0 60px;
	width: 100%;
}

.postcontent,
.sidebar,
.col_full,
.col_half,
.col_one_third,
.col_two_third,
.col_three_fourth,
.col_one_fourth,
.col_one_fifth,
.col_two_fifth,
.col_three_fifth,
.col_four_fifth,
.col_one_sixth,
.col_five_sixth {
	display: block;
	position: relative;
	margin-right: 4%;
	margin-bottom: $margin-base;
	float: left;
}

.postcontent,
.sidebar { margin-right: $postcontent-margin; }

.col_full {
	clear: both;
	float: none;
	margin-right: 0;
}

.postcontent.col_last,
.sidebar.col_last { float: right; }

.col_last {
	margin-right: 0 !important;
	clear: right;
}