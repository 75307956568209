
/*-----------------------------------------------------------------------------------

	Shortcodes: promo.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/

.promo {
	position: relative;
	padding: 30px 200px 30px 0;
	h3 {
		font-weight: bold;
		margin-bottom: 0;
	}
	a {
		&.button {
			position: absolute;
			top: 50%;
			margin: 0;
			margin-top: -18px;
			left: auto;
			right: 0;
		}
	}
	> span,
	> .container > span {
		display: block;
		color: #444;
		font-weight: 300;
		font-size: 16px;
		margin-top: 6px;
	}
	a {
		&.button {
			&.button-mini {
				margin-top: -12px;
			}
			&.button-small {
				margin-top: -15px;
			}
			&.button-large {
				margin-top: -22px;
			}
			&.button-xlarge {
				margin-top: -25px;
			}
			&.button-desc {
				margin-top: -40px;
			}
		}
	}
}

/* Promo Uppercase
-----------------------------------------------------------------*/

.promo-uppercase {
	text-transform: uppercase;
	h3 {
		font-size: 22px;
	}
}
.promo-uppercase {
	> span,
	> .container > span {
		font-size: 15px;
	}
}

/* Promo - with Border
-----------------------------------------------------------------*/

.promo {
	&.promo-border {
		border: 1px solid #E5E5E5;
		@include border-radius(3px);
		padding-left: 30px;
		a {
			&.button {
				right: 30px;
			}
		}
	}
}

/* Promo - Light
-----------------------------------------------------------------*/

.promo {
	&.promo-light {
		background-color: #F5F5F5;
		@include border-radius(3px);
		padding-left: 30px;
		a {
			&.button {
				right: 30px;
			}
		}
	}
}

/* Promo - Dark
-----------------------------------------------------------------*/

.promo {
	&.promo-dark {
		border: 0 !important;
		background-color: #333;
		padding: 30px 200px 30px 30px;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
		@include border-radius(3px);
		h3 {
			color: #FFF;
		}
		a {
			&.button {
				right: 30px;
			}
		}
		> h3 span,
		> .container > h3 span {
			padding: 2px 8px;
			@include border-radius(3px);
			background-color: rgba(0,0,0,0.15);
			color: #FFF;
			font-weight: 400;
		}
		 > span,
		> .container > span {
			color: #CCC;
			margin-top: 8px;
		}
	}
}


/* Promo - Flat
-----------------------------------------------------------------*/

.promo {
	&.promo-flat {
		background-color: $theme-color;
		a {
			&.button {
				&:not(.button-border) {
					background-color: rgba(0,0,0,0.2);
					color: #FFF;
				}
				&:hover {
					background-color: #FFF;
					border-color: #FFF;
					color: #444;
					text-shadow: none;
				}
			}
		}
		> span,
		.promo.promo-flat > .container > span {
			color: rgba(255,255,255,0.9);
		}

	}
}

/* Promo - 100% Full Width
-----------------------------------------------------------------*/

.promo-full {
	@include border-radius(3px !important);
	border-left: 0 !important;
	border-right: 0 !important;
	padding: 40px 0 !important;
	a {
		&.button {
			right: 15px !important;
		}
	}
	&.promo-right {
		a {
			&.button {
				right: auto !important;
				left: 15px !important;
			}
		}
	}
}


/* Promo - Parallax
-----------------------------------------------------------------*/

.promo {
	&.parallax {
		padding: 80px 0 !important;
	}
}


/* Promo - Right Align
-----------------------------------------------------------------*/

.promo {
	&.promo-right {
		text-align: right;
		padding: 30px 0 30px 200px;
		a {
			&.button {
				left: 0;
				right: auto;
			}
		}
	}
}

/* Promo - with Border & Right Align
-----------------------------------------------------------------*/

.promo {
	&.promo-right.promo-border,
	&.promo-dark.promo-right {
		:not(.promo-mini) {
			padding-right: 30px;
		}
		a.button {
			left: 30px;
		}
	}
}

/* Promo - Center Align
-----------------------------------------------------------------*/

.promo {
	&.promo-center {
		text-align: center;
		padding: 30px 20px;
		a {
			&.button {
				position: relative;
				top: 0;
				margin: 20px 0 0;
				left: 0 !important;
				right: 0;
			}
		}
	}
}

/* Promo - Mini
-----------------------------------------------------------------*/

.promo {
	&.promo-mini {
		text-align: left;
		padding: 20px 25px;
		h3 {
			font-size: 20px;
			line-height: 1.5;
		}
		&.promo-uppercase {
			h3 {
				font-size: 18px;
			}
		}
		&.promo-dark {
			h3 {
				line-height: 1.7;
			}
		}
		a {
			&.button {
				position: relative;
				top: 0;
				margin: 20px 0 0;
				left: 0 !important;
				right: 0;
			}
		}
	}
}

/* Promo - Mini - Center & Right Align
-----------------------------------------------------------------*/

.promo {
	&.promo-mini {
		&.promo-center {
			text-align: center;
		}
		&.promo-right {
			text-align: right;
		}
	}
}