a.skipcontent {
  position: absolute;
  top: -40px;
  left: 20px;
  z-index: 2000;
  padding: 6px 8px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: transparent;
  color: white;
  -webkit-transition: top 1s ease-out, background 1s linear;
  transition: top 1s ease-out, background 1s linear;
  &:focus {
    position: absolute;
    top: 0;
    left: 20px;
    outline: 0;
    background: #bf1722;
    -webkit-transition: top 0.1s ease-in, background 0.5s linear;
    transition: top 0.1s ease-in, background 0.5s linear;
  }
}

.sidebar a.skipcontent {
  top: 32px;
  left: 0;
  z-index: -1;
  width: calc(100% - 63px);
  background: #649fd7;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  &:focus {
    top: 65px;
    z-index: 2000;
    opacity: 1;
  }
}

.btn-hidden-text {
  display: block;
  overflow: hidden;
  margin: 0;
  height: 0;
  font-size: 0;
}