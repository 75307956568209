.homepage .social {
  position: fixed;
  top: 45%;
  left: -6px;
  z-index: 100;
}

.social {
  width: 55px;
  ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
  }
  li {
    overflow: hidden;
    margin: 2px;
    padding: 4px;
    white-space: nowrap;
    a {
      display: block;
      width: calc(100% + 10px);
      color: transparent;
      &:hover,
      &:visited,
      &:focus {
        color: transparent;
      }
    }
  }
  span {
    width: 45px;
    background: #222222;
    color: #ffffff;
    text-align: center;
    font-size: 1.5em;
    line-height: 45px;
  }
  a:hover {
    span {
      background: #ffffff !important;
      color: #222222;
    }
  }
}
