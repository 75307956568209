
/* ----------------------------------------------------------------

	topbar.scss

-----------------------------------------------------------------*/


#top-bar {
	position: relative;
	border-bottom: 1px solid #EEE;
	height: $topbar-height;
	line-height: $topbar-line-height;
	font-size: 13px;

	.col_half {
		width: auto;
	}
}


/* ----------------------------------------------------------------
	Top Links
-----------------------------------------------------------------*/


.top-links {
	position: relative;
	float: left;

	ul {
		margin: 0;
		list-style: none;
		li {
			float: left;
			position: relative;
			height: $topbar-line-height;
			border-left: 1px solid #EEE;
		}
		li:first-child,
		ul li {
			border-left: 0 !important;
		}
	}

	li {
		> a {
			display: block;
			padding: 0 12px;
			font-size: 12px;
			font-weight: 600;
			text-transform: uppercase;
			height: $topbar-line-height;
			color: #666;
		}
		i { vertical-align: top;}
		i.icon-angle-down {
			margin: 0 0 0 5px !important;
		}
		i:first-child {
			margin-right: 2px;
		}
		&.full-icon i {
			top: 2px;
			font-size: 14px;
			margin: 0;
		}
		&:hover {
			background-color: #EEE;

			ul,
			div.top-link-section {
				pointer-events: auto;
			}
		}
	}

	ul {
		ul,
		div.top-link-section {
			display: none;
			pointer-events: none;
			position: absolute;
			z-index: 210;
			line-height: 1.5;
			background: #FFF;
			border: 0;
			top: $topbar-line-height;
			left: 0;
			width: 140px;
			margin: 0;
			border-top: 1px solid $theme-color;
			border-bottom: 1px solid #EEE;
			@include box-shadow(0 0 5px -1px rgba(0,0,0,0.2));
		}

		ul {
			li {
				float: none;
				height: 36px;
				border-top: 1px solid #F5F5F5;
				border-left: 1px solid #EEE;

				&:hover {
					background-color: #F9F9F9;
				}

				&:first-child {
					border-top: none !important;
					border-left: 1px solid #EEE;
				}
			}

			a {
				height: 36px;
				line-height: 36px;
				font-size: 12px;
			}

			img {
				display: inline-block;
				position: relative;
				top: -1px;
				width: 16px;
				height: 16px;
				margin-right: 4px;
			}

			&.top-demo-lang img {
				top: 4px;
				width: 16px;
				height: 16px;
			}
		}

		div.top-link-section {
			padding: 25px;
			left: 0;
			width: 280px;
		}
	}
}

.fright .top-links ul div.top-link-section,
.top-links.fright ul div.top-link-section {
	left: auto;
	right: 0;
}


/* ----------------------------------------------------------------
	Top Social
-----------------------------------------------------------------*/

#top-social,
#top-social ul { margin: 0; }

#top-social {
	li,
	li a,
	li .ts-icon,
	li .ts-text {
		display: block;
		position: relative;
		float: left;
		width: auto;
		overflow: hidden;
		height: $topbar-line-height;
		line-height: $topbar-line-height;
	}

	li {
		border-left: 1px solid #EEE;

		&:first-child {
			border-left: 0 !important;
		}

		a {
			float: none;
			width: 40px;
			font-weight: bold;
			color: #666;
			@include transition(color .3s ease-in-out, background-color .3s ease-in-out, width .3s ease-in-out);
			&:hover {
				color: #FFF !important;
				text-shadow: 1px 1px 1px rgba(0,0,0,0.2);
			}
		}

		.ts-icon {
			width: 40px;
			text-align: center;
			font-size: 14px;
		}
	}
}


/* ----------------------------------------------------------------
	Top Login
-----------------------------------------------------------------*/

#top-login {
	margin-bottom: 0;
	.checkbox { margin-bottom: 10px; }
	.form-control { position: relative;}
	.form-control:focus { border-color: #CCC; }
	.input-group#top-login-username { margin-bottom: -1px; }
	#top-login-username input,
	#top-login-username .input-group-addon {
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	.input-group#top-login-password { margin-bottom: 10px;}
	#top-login-password input,
	#top-login-password .input-group-addon {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}