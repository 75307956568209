/* ----------------------------------------------------------------
	Responsive CSS
-----------------------------------------------------------------*/



@media (min-width: 992px) and (max-width: 1199px) {


	/* Portfolio - Both Sidebars - Items - 2 Columns
	-----------------------------------------------------------------*/

	.bothsidebar .portfolio.portfolio-3 { margin: 0 0 -20px -20px; }

	.bothsidebar .portfolio-2 .portfolio-item,
	.bothsidebar .portfolio-3 .portfolio-item { padding: 0 0 20px 20px; }

	.bothsidebar .entry-meta li {
		margin-left: 10px;
		margin-right: 0;
	}

	.landing-video { margin: 19px 75px 0 0; }

	.panel .portfolio-meta li {
		padding-right: 22px;
		padding-left: 0;
	}

	.panel .portfolio-meta li span {
		margin-right: -21px;
		margin-left: 0;
	}

}


@media (max-width: 991px) {

	#primary-menu-trigger {
		right: 0;
		left: auto;
	}

	#primary-menu .container #primary-menu-trigger {
		right: 0;
		left: auto;
	}

	body.side-push-panel #side-panel-trigger-close a {
		right: auto;
		left: 0;
	}

	#primary-menu ul li { text-align: right !important; }

	#primary-menu.style-5 > ul > li > a > div i { margin: 0 0 0 6px; }

	#primary-menu > ul > li.sub-menu > a,
	#primary-menu > .container > ul > li.sub-menu > a { background-position: left center; }


	#primary-menu ul ul:not(.mega-menu-column),
	#primary-menu ul li .mega-menu-content {
		padding-right: 15px;
		padding-left: 0;
	}

	#primary-menu ul li .mega-menu-content { padding-right: 0; }

	#primary-menu ul ul:not(.mega-menu-column) ul {
		right: 0;
		left: auto;
	}

	#primary-menu ul ul.menu-pos-invert:not(.mega-menu-column),
	#primary-menu ul li .mega-menu-content.menu-pos-invert {
		right: 0;
		left: 0;
	}

	#primary-menu ul ul:not(.mega-menu-column) ul.menu-pos-invert {
		left: 0;
		right: auto;
	}

	#primary-menu ul ul li:hover > a {
		padding-right: 5px;
		padding-left: 0;
	}

	#primary-menu ul li .mega-menu-content ul {
		right: 0;
		left: auto;
	}

	#primary-menu ul li .mega-menu-content ul.mega-menu-column {
		border-right: 0 !important;
		padding-left: 0;
		padding-right: 15px;
	}
	#primary-menu ul li .mega-menu-content.style-2 ul.mega-menu-column { padding: 0 15px 0 0; }

	#primary-menu ul li .mega-menu-content.style-2 ul > li > a:hover {
		padding-right: 5px;
		padding-left: 0;
	}

	#top-search a,
	#top-cart,
	#side-panel-trigger,
	#top-account {
		left: 15px;
		right: auto;
	}

	#primary-menu ul.windows-mobile-menu li.sub-menu a.wn-submenu-trigger {
		left: 0;
		right: auto;

	}

	#top-search a {
		left: 43px;
		right: auto;
	}

	#side-panel-trigger {
		left: 71px;
		right: auto;
	}

	#page-menu nav {
		right: auto;
		left: 15px;
	}

	#page-menu nav li { float: none; }

	#page-menu ul ul a {
		padding-right: 25px;
		padding-left: 0;
	}

	#top-account {
		left: 75px;
		right: auto;
	}

	/* Responsive Sticky Header
	-----------------------------------------------------------------*/

	.responsive-sticky-header #header-wrap {
		right: 0;
		left: auto;
	}

	/* Portfolio - Items - 1 Column - Full Width
	-----------------------------------------------------------------*/

	.portfolio-1.portfolio-fullwidth .portfolio-overlay a {
		right: 50%;
		left: auto;
	}

	.portfolio-1.portfolio-fullwidth .alt .portfolio-overlay a {
		right: auto;
		left: 50%;
	}

	.entry-meta li { margin: 0 0 10px 10px; }


	/* Blog - Grid
	-----------------------------------------------------------------*/

	.post-grid {
		margin-left: -20px;
		margin-right: 0;
	}

	/* Blog - Grid - 3 Columns
	-----------------------------------------------------------------*/

	.post-grid.grid-2,
	.post-grid.grid-3 {
		margin-left: -30px;
		margin-right: 0;
	}

	.post-grid.grid-2 .entry,
	.post-grid.grid-3 .entry {
		margin-right: 0;
		margin-left: 30px;
	}


	.pricing-box.pricing-extended .pricing-action-area {
		border-left: 0;
		border-right: 0;
	}

	.landing-form-overlay {
		left: auto;
		right: 50%;
		margin-right: -200px;
		margin-left: 0;
	}

}



@media (min-width: 768px) and (max-width: 991px) {

	.sidebar-widgets-wrap { margin: 0 0 -40px -38px; }

	.sidebar-widgets-wrap .widget { margin: 0 0 40px 37px; }

	.landing-video { margin: 14px 60px 0 0; }

}


@media (max-width: 767px) {

	.top-links > ul > li {
		margin-left: -4px;
		margin-right: 0;
	}

	.top-links ul ul,
	.top-links ul div.top-link-section { text-align: right; }

	.top-links ul div.top-link-section {
		right: auto;
		left: 0;
	}

	.postcontent,
	.postcontent.bothsidebar,
	.sidebar,
	.col_full,
	.col_half,
	.col_one_third,
	.col_two_third,
	.col_three_fourth,
	.col_one_fourth,
	.col_one_fifth,
	.col_two_fifth,
	.col_three_fifth,
	.col_four_fifth,
	.col_one_sixth,
	.col_five_sixth { margin-left: 0; }

	#page-title #portfolio-navigation,
	#portfolio-ajax-title #portfolio-navigation { right: 0; }

	/* Portfolio - Filter
	-----------------------------------------------------------------*/

	.portfolio-filter li a {
		border-right: none;
		border-left: none;
	}

	/* Blog
	-----------------------------------------------------------------*/

	.entry-meta li { margin: 0 10px 10px 0; }

	.post-grid { margin-left: 0 !important; }

	.post-grid .entry,
	.post-masonry-full.grid-3 .entry {
		margin-left: 0 !important;
		border-left: 0 !important;
		border-right: 0;
	}

	.post-timeline .entry.entry-date-section { margin-right: 0; }

	.postcontent .post-timeline { padding-right: 0; }

	.team.team-list .team-image { margin-left: 0; }

	.title-block {
		padding: 2px 0 3px 15px;
		border-right-width: 5px;
		border-left-width: 0;
	}

	.title-block-right {
		padding: 2px 15px 3px 0;
		border-left-width: 5px;
		border-right-width: 0;
	}

}

@media (min-width: 480px) and (max-width: 767px) {

	#top-search form input {
		padding-left: 60px;
		padding-right: 0;
	}

	/* Portfolio - Items
	-----------------------------------------------------------------*/

	.portfolio:not(.portfolio-1) { margin: 0 0 -6px -6px; }

	.portfolio:not(.portfolio-1) .portfolio-item { padding: 0 0 6px 6px; }

	.portfolio:not(.portfolio-1) .portfolio-item:hover .portfolio-overlay .portfolio-desc ~ a.right-icon {
		margin-right: 5px !important;
		margin-left: 0 !important;
	}

	.portfolio-1.portfolio-fullwidth .portfolio-overlay a {
		right: 50%;
		left: auto;
	}

	.portfolio-1.portfolio-fullwidth .alt .portfolio-overlay a {
		right: auto;
		left: 50%;
	}

	.shop:not(.product-1) { margin: 0 0 -12px -12px; }

	.shop:not(.product-1) .product { padding: 0 0 12px 12px; }

	.button.button-full i {
		margin-left: 0;
		margin-right: 3px !important;
	}

	.landing-video { margin: 19px 0 0 53px; }

}

@media (max-width: 479px) {

	#top-search a {
		right: auto;
		left: 15px;
	}

	#top-search form input {
		padding-left: 30px;
		padding-right: 0;
	}

	#side-panel-trigger {
		left: 43px;
		right: auto;
	}

	#top-account {
		left: 43px;
		right: auto;
	}

	.parallax .entry-overlay-meta,
	.parallax .entry-overlay {
		right: 50% !important;
		left: 0 !important;
		margin: 0 0 0 -140px !important;
	}

	.button.button-full i {
		margin-right: 3px !important;
		margin-left: 0;
	}

	.landing-video { margin: 19px 0 0 34px; }

	.landing-form-overlay {
		margin-right: -155px;
		margin-left: 0;
	}

	.side-tabs ul.tab-nav li.ui-tabs-active a,
	.tabs-bordered.side-tabs ul.tab-nav li.ui-tabs-active a {
		left: 0;
		right: auto;
	}

}
