span.twitter-typeahead {
  display: block !important;
}

.tt-menu {
  margin: 4px 0;
  padding: 8px 0;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 1px 10px rgba(0,0,0,0.1);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 15px;
  line-height: 24px;
}

.tt-suggestion:hover {
  background-color: #0097cf;
  color: #ffffff;
  cursor: pointer;
}

.tt-suggestion.tt-cursor {
  background-color: #0097cf;
  color: #ffffff;
}
