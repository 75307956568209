.content-containers {
  .content-container {
    display: none;
    &.active {
      display: block;
    }
  }
}

.sidenav {
  margin-left: 0;
  li {
    margin-left: 0;
  }
}
