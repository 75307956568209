
/*-----------------------------------------------------------------------------------

	Shortcodes: team.scss

-----------------------------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/


.team,
.team-image {
  position: relative;
}

.team .team-image {
  > a,
  img {
    display: block;
    margin: 0;
    width: 100%;
  }
}

.team-desc {
  text-align: center;
  &.team-desc-bg {
    padding: 10px 0 30px;
    background-color: #ffffff;
  }
}
.team {
  .si-share {
    margin-top: 30px;
    text-align: left;
    .social-icon {
      width: 34px;
      font-size: 14px;
    }
  }
}
.team-title {
  position: relative;
  padding-top: 20px;
  h4 {
    display: block;
    margin-bottom: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 19px;
  }
  span {
    display: block;
    margin-top: 5px;
    color: $theme-color;
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    font-family: $secondary-font;
  }
}

.team-content {
  margin-top: 15px;
  color: #777777;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.team-overlay,
.team-overlay:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0;
}
body {
  &:not(.device-touch) {
    .team-overlay {
      @include transition(opacity 0.3s ease);
    }
  }
}
.team-overlay {
  &:before {
    z-index: auto;
    background-color: #ffffff;
    content: '';
    opacity: 0.75;
  }
  .social-icon {
    position: relative;
    z-index: 3;
    display: inline-block;
    float: none;
    margin: 0 3px !important;
  }
}
.team {
  &:hover {
    .team-overlay {
      opacity: 1;
    }
  }
}

/* Team Center
-----------------------------------------------------------------*/

.team.center {
  .team-title {
    &:after {
      left: 50%;
      margin-left: -40px;
    }
  }
}

/* Team List
-----------------------------------------------------------------*/

.team {
  &.team-list {
    .team-image {
      float: left;
      margin-right: 30px;
      width: 250px;
    }
    .team-desc {
      position: relative;
      overflow: hidden;
      text-align: left;
    }
    .team-title {
      padding-top: 0;
    }
  }
  .team-desc {
    & > .social-icon {
      margin-top: 20px;
    }
  }
  .team-content {
    & ~ .social-icon {
      margin-top: 30px;
    }
  }
  &:not(.team-list) {
    .social-icon {
      display: inline-block;
      float: none;
      margin-right: 6px;
      margin-left: 6px;
    }
  }
}
