.tweets {
  ul {
    list-style: none;
  }
  li {
    position: relative;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed #e5e5e5;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: 0;
    }
    &:before {
      position: absolute;
      left: -65px;
      display: block;
      width: 55px;
      height: 55px;
      background: url(/themes/canvas-template/assets/dist/images/logo.png);
      background-size: contain;
      content: '';
    }
  }
  img {
    display: none;
  }
  .user {
    font-weight: 600;
  }
  .user,
  .tweet {
    margin: 0;
  }
  .interact,
  .media {
    display: none;
  }
}
