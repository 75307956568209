.content {
  img {
    margin: 0;
    &[style*='left'] {
      margin-right: 20px;
    }
    &[style*='right'] {
      margin-left: 20px;
    }
  }
}

#content .content-wrap {
  @media (max-width: 980px) {
    padding: 0;
  }
}

.county {
  text-transform: capitalize;
  &.badge {
    @media (max-width: 480px) {
      position: absolute;
      top: 0;
      right: 0;
      overflow: hidden;
      width: 65px;
      height: 100%;
      border-left: 1px solid #555555;
      border-radius: 0;
      background: #ffffff;
      color: #555555;
      line-height: 35px;
    }
  }
}

.list-group {
  margin: 0;
}

.list-group-item {
  margin: 0;
  border: 0;
}

.google-map-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  #map {
    height: 100%;
  }
  @media (max-width: 992px) {
    position: static;
    display: block;
    width: 100%;
    height: 340px;
  }
}

.contact-modal {
  div {
    clear: both;
  }
  .label {
    float: left;
    margin-top: 1px;
    margin-bottom: 3px;
    width: 60px;
  }
}

.block {
  display: block;
  float: none !important;
  width: auto !important;
}

.contact-widget {
  margin-top: -25px;
}

.content-form {
  input,
  textarea {
    display: block;
    width: 100%;
    &[type='submit'] {
      width: auto;
    }
  }
}

.content-form {
  label {
    margin-top: 10px;
    margin-bottom: 0;
  }
  input[type='text'],
  textarea,
  select,
  input[type='numeric'] {
    display: block;
    padding: 8px 14px;
    width: 100%;
    height: auto;
    border: 2px solid #dddddd;
    border-radius: 0 !important;
    background-color: #ffffff;
    background-image: none;
    color: #555555;
    font-size: 15px;
    line-height: 1.42857143;
    transition: border-color ease-in-out 0.15s;
  }
  input[type='checkbox'],
  input[type='radio'] {
    display: inline;
    margin: 0 5px 2px 5px;
    width: auto;
    vertical-align: middle;
  }
  input[type='submit'] {
    margin-left: 15px;
  }
  span.seats-available {
    position: absolute;
    top: 12px;
    right: 20px;
    font-size: 11px;
  }
  .pmt-total p {
    font-weight: 800;
    font-size: 1.25em;
  }
  .pmt-check p {
    margin: 20px;
    white-space: pre;
    font-weight: 800;
    font-size: 1.25em;
  }
}

.sticky-header {
  #primary-menu {
     > ul > li:last-child a {
      margin: 4px 0;
      line-height: 15px;
    }
  }
}

#primary-menu {
   > ul > li:last-child a {
    position: relative;
    display: inline-block;
    margin: 22px 0;
    padding: 0 32px;
    height: 52px;
    outline: none;
    border: none;
    border-bottom: 3px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    background-color: #649fd7;
    color: #ffffff;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    white-space: nowrap;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 18px;
    line-height: 52px;
    cursor: pointer;
    @media (max-width: 991px) {
      width: 100%;
      height: auto;
      text-align: center;
    }
  }
}

.text-right {
  text-align: right !important;
}

.slider-caption {
  h2,
  p {
    text-shadow: #000000 4px 4px 0 !important;
  }
}

.vs-content-container,
.page-header {
  clear: both;
}

.entry-meta {
  max-height: 30px;
}

.entry-content,
.postcontent {
  img {
    margin: 0 20px 20px;
    &[style*='left'] {
      margin-left: 0;
    }
    &[style*='right'] {
      margin-right: 0;
    }
  }
}

#logo a[class*='-logo'] {
  float: left;
}

a.feed-america-logo {
  margin-left: 20px;
  img {
    max-height: 60px;
  }
}

.team-title {
  h4 {
    white-space: nowrap;
  }
  span {
    height: 45px;
  }
}

.show-bio {
  cursor: pointer;
}

.bio-modal {
  .modal-body {
    display: flex;
  }
  .team-desc {
    margin: -25px 25px;
    max-width: 75%;
    text-align: left;
  }
}

.mobile-button {
  display: block;
  margin: 20px;
  text-align: center;
}

iframe {
  max-width: 100%;
}

#footer {
  margin-top: 40px;
}

dl,
dt,
dd,
ol,
ul,
li {
  margin-left: 30px;
  padding-left: 10px;
}

.Employment div[id*='Employment'] h2 {
  display: none;
  &:first-child {
    display: block;
  }
}

@media (min-width: 980px) {
  .hide-for-large-up {
    display: none;
  }
}

@media (max-width: 980px) {
  div[class*='col-'] {
    height: auto !important;
  }
}

@media (max-width: 991px) {
  #primary-menu-trigger {
    right: -30px;
    left: auto;
  }
}

//CIFB Events - Homepage
#home-recent-news {
  .entry-image {
    a {
      padding: 5px 10px;
      border: 3px solid;
      border-radius: 50%;
    }
    .event-icon {
      position: relative;
      top: 0;
      font-size: 1.75em;
      transition: top 0.5s;
    }
    .short-month {
      position: relative;
      top: 2px;
      display: block;
      text-align: center;
      font-size: 0.75em;
      transition: top 0.5s;
    }
    .date-num {
      position: relative;
      top: 0;
      display: block;
      text-align: center;
      font-size: 1.65em;
      line-height: 0.75em;
      transition: top 0.5s;
    }
  }
  .spost:hover {
    .entry-image {
      .event-icon,
      .short-month,
      .date-num {
        top: -35px;
      }
    }
  }
  .entry-title {
    h4 a {
      color: #649fd7;
    }
  }
  .entry-meta {
    margin: 4px 0 0 0;
    padding: 0;
    li {
      clear: both;
      margin: 0;
      padding: 0;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      font-style: normal;
      font-family: 'Raleway', sans-serif;
    }
  }
  .entry-content {
    clear: both;
    margin-top: 30px;
  }
}

.tt-suggestion {
  text-transform: capitalize;
}

.give-help.dark {
  .fancy-title h4 {
    background: #649fd7;
    color: #ffffff;
  }
}

.locations-list {
  #content {
    position: relative;
    padding: 20px;
    height: 240px;
    border: 1px solid #efefef;
  }
  a {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

#primary-menu {
  @media only screen and (min-width: 991px) and (max-width: 1250px) {
    margin: 0 -30px;
    width: calc(100% + 60px);
    background: rgb(255, 255, 255);
  }
}

#header.full-header #primary-menu > ul {
  @media only screen and (min-width: 991px) and (max-width: 1250px) {
    width: calc(100% - 80px);
  }
}

#top-search {
  @media only screen and (min-width: 991px) and (max-width: 1250px) {
    margin-right: 35px;
  }
}

.twitter-typeahead {
  float: left;
  width: calc(100% - 63px);
}

.typeahead-submit {
  margin-left: 5px;
  width: 58px;
  height: 34px;
}
